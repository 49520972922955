import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  isDevMode,
  viewChild,
  ViewContainerRef
} from "@angular/core";
import { Content } from "../../interfaces/content.interface";
import { ComponentManagerService } from "../../services/component-manager.service";

@Component({
  selector: "cg-cms-cgx-host-container",
  templateUrl: "./cms-cgx-host-container.component.html",
  styleUrls: ["./cms-cgx-host-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class CmsCgxHostContainerComponent {
  private readonly componentManagerService = inject(ComponentManagerService);

  public content = input<Content>();
  public host = viewChild("host", { read: ViewContainerRef });

  public mountComponent(): void {
    if (!this.content() || !Object.prototype.hasOwnProperty.call(this.content(), "ngTemplate")) {
      throw new Error(
        `Can't render component for content without ngTemplate property. Content: ${JSON.stringify(this.content())}`
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = this.componentManagerService.getComponentByTemplateId(this.content().ngTemplate);

    if (!component) {
      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.log(`Component not found for ${this.content().ngTemplate}`);
      }
      return;
    }

    this.host().clear();

    const componentRef = this.host().createComponent(component);
    componentRef.setInput("content", this.content());
  }

  public constructor() {
    effect(
      () => {
        if (this.content()) {
          this.mountComponent();
        }
      },
      { allowSignalWrites: true }
    );
  }
}
