import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { type ApplicationConfig, LOCALE_ID } from "@angular/core";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from "@angular/router";
import { provideRouterStore, routerReducer } from "@ngrx/router-store";
import { type Action, type MetaReducer, provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { provideAppInitializer, provideServerClientState, stateTransferReducer } from "@cg/core";
import { provideInsurance } from "@cg/cost-check";
import { provideFeatureToggleState } from "@cg/feature-toggle";
import { provideOlbConfig } from "@cg/olb/configuration";
import { provideSentry, sentryBreadcrumbMetaReducer, sentryHttpErrorInterceptor } from "@cg/sentry";
import { provideSpinner, spinnerInterceptor } from "@cg/spinner";
import { provideTestbot, testbotInterceptor } from "@cg/testbot";
import { provideTranslation } from "@cg/translation";
import { provideAnalytics } from "@cg/analytics";
import { provideCms } from "@cg/cms";
import { provideConfig } from "@cg/config";
import { authenticationInterceptor, provideCoreApi } from "@cg/core/api";
import { provideFooter } from "@cg/footer";
import { provideHeader } from "@cg/header";
import { provideNavigation } from "@cg/navigation";
import { EntryChannel } from "@cg/shared";
import { analyticsConfiguration } from "./analytics.config";
import { routes } from "./routes";

const metaReducers: MetaReducer<unknown, Action>[] = [stateTransferReducer, sentryBreadcrumbMetaReducer];

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(),
    {
      provide: LOCALE_ID,
      useValue: "de"
    },
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([spinnerInterceptor, authenticationInterceptor, sentryHttpErrorInterceptor, testbotInterceptor])
    ),
    provideServerClientState(),
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled"
      }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    provideSentry({ dsn: "https://d6d3aefab90b416fbd4681ded2818271@sentry.io/1463454" }),
    provideTranslation(),
    provideStore(
      {
        router: routerReducer
      },
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }
      }
    ),
    provideRouterStore(),
    provideStoreDevtools({
      name: "Carglass",
      maxAge: 100,
      connectInZone: true
    }),
    provideSpinner(),
    provideCoreApi(),
    provideTestbot(),
    provideCms(),
    provideFooter(),
    provideNavigation(),
    provideHeader(),
    provideConfig(["protect_OLB", "repair_OLB", "wiper_OLB", "appointments", "cccOpeningHours", "opportunity"]),
    provideOlbConfig({ entryChannel: EntryChannel.CARGLASS }),
    provideAnalytics(analyticsConfiguration),
    provideFeatureToggleState(),
    provideInsurance()
  ]
};
