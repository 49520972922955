import { HttpErrorResponse, type HttpHandlerFn, type HttpHeaders, type HttpRequest } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as Sentry from "@sentry/angular-ivy";

function headerToObject(headers: HttpHeaders): Record<string, string> {
  return headers.keys().reduce((acc: unknown, key: string) => {
    acc[key] = headers.get(key);
    return acc;
  }, {});
}

function messageToString(message: unknown): string {
  switch (typeof message) {
    case "object":
      return JSON.stringify(message);
    case "string":
      return message;
    default:
      return message.toString();
  }
}

function addBreadcrumb(category: string, message: unknown): void {
  Sentry.addBreadcrumb({
    category,
    message: messageToString(message),
    level: "error"
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sentryHttpErrorInterceptor(req: HttpRequest<any>, next: HttpHandlerFn) {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!(error instanceof HttpErrorResponse)) {
        return;
      }

      if (req.body) {
        addBreadcrumb("http-req-body", {
          url: `${req.method} - ${req.urlWithParams.toString()}`,
          reqBody: req.body
        });
      }

      addBreadcrumb("http-req-header", {
        url: `${req.method} - ${req.urlWithParams.toString()}`,
        reqHeader: headerToObject(req.headers)
      });

      addBreadcrumb("http-response-status", {
        url: `${req.method} - ${req.urlWithParams.toString()}`,
        status: `${error.status} - ${error.statusText}`
      });

      addBreadcrumb("http-response-header", {
        url: `${req.method} - ${req.urlWithParams.toString()}`,
        resHeader: headerToObject(error.headers)
      });

      addBreadcrumb("http-response-body", {
        url: `${req.method} - ${req.urlWithParams.toString()}`,
        resBody: error.error
      });

      return throwError(() => error);
    })
  );
}
