import {
  ContactWidgetComponent as CgxContactWidgetComponent,
  ContentBlockImageComponent as CgxContentBlockImageComponent,
  ContentBlockListComponent as CgxContentBlockListComponent,
  ContentBlockTextComponent as CgxContentBlockTextComponent,
  ContentBlockVideoComponent as CgxContentBlockVideoComponent,
  CostCheckComponent as CgxCostCheckComponent,
  DamageTypeTableComponent as CgxDamageTypeTableComponent,
  LocationTeaserComponent as CgxLocationTeaserComponent,
  MsgComponent as CgxMsgComponent,
  StageHomeComponent as CgxStageHomeComponent,
  StageSubComponent as CgxStageSubComponent,
  TeaserComponent as CgxTeaserComponent,
  TrustNumbersComponent as CgxTrustNumbersComponent,
  TrustpilotComponent as CgxTrustpilotComponent,
  UspsComponent as CgxUspsComponent,
  WidgetsComponent as CgxWidgetsComponent
} from "@cg/cgx-ui/containers";
import {
  CtaComponent as CgxCtaComponent,
  HeadlineComponent as CgxHeadlineComponent,
  ParagraphComponent as CgxPargraphComponent,
  PictureComponent as CgxPictureComponent
} from "@cg/cgx-ui/core";
import { CockpitComponent } from "@cg/cockpit";
import { ContactFormComponent } from "@cg/contact-form";
import { CostCheckComponent } from "@cg/cost-check";
import { StageHomeComponent, StageSubAlternativeComponent, StageSubComponent } from "@cg/stage";
import { TeaserServicesComponent, TeaserThreeColumnComponent, TeaserTwoColumnComponent } from "@cg/teaser";
import { TrustComponent } from "@cg/trust";
import {
  ContentBlockColumnComponent,
  ContentBlockContactComponent,
  ContentBlockImageVideoHorizontalComponent,
  ContentBlockImageVideoVerticalComponent,
  ContentBlockIntroComponent,
  ContentBlockListComponent
} from "@cg/content-block";
import { H1HeadlineComponent, PictureComponent, RichtextComponent } from "@cg/core/ui";
import { CustomerratingTeaserComponent } from "@cg/customerrating";
import { DistributorCardComponent, DistributorComponent } from "@cg/distributor";
import { CtaComponent, TableComponent } from "@cg/shared";
import type { ComponentMap } from "../interfaces/component-map.interface";

export const componentMap: ComponentMap[] = [
  {
    templateId: "cgStageHome",
    component: StageHomeComponent
  },
  {
    templateId: "cgStageSubpage",
    component: StageSubComponent
  },
  {
    templateId: "cgStageSubpageAlternative",
    component: StageSubAlternativeComponent
  },
  {
    templateId: "cgPicture",
    component: PictureComponent
  },
  {
    templateId: "cgCta",
    component: CtaComponent
  },
  {
    templateId: "cgTeaserTwoColumn",
    component: TeaserTwoColumnComponent
  },
  {
    templateId: "cgTeaserThreeColumn",
    component: TeaserThreeColumnComponent
  },
  {
    templateId: "cgContentBlockColumn",
    component: ContentBlockColumnComponent
  },
  {
    templateId: "cgContentBlockIntro",
    component: ContentBlockIntroComponent
  },
  {
    templateId: "cgContentBlockImageVideoVertical",
    component: ContentBlockImageVideoVerticalComponent
  },
  {
    templateId: "cgContentBlockImageVideoHorizontal",
    component: ContentBlockImageVideoHorizontalComponent
  },
  {
    templateId: "cgContentBlockList",
    component: ContentBlockListComponent
  },
  {
    templateId: "cgContentBlockContact",
    component: ContentBlockContactComponent
  },
  {
    templateId: "cgTeaserServices",
    component: TeaserServicesComponent
  },
  {
    templateId: "cgTable",
    component: TableComponent
  },
  {
    templateId: "cgDistributor",
    component: DistributorComponent
  },
  {
    templateId: "cgDistributorCard",
    component: DistributorCardComponent
  },
  {
    templateId: "cgCustomerRatingsTeaser",
    component: CustomerratingTeaserComponent
  },
  {
    templateId: "cgH1Headline",
    component: H1HeadlineComponent
  },
  {
    templateId: "cgTrust",
    component: TrustComponent
  },
  {
    templateId: "cgRichtext",
    component: RichtextComponent
  },
  {
    templateId: "cgCockpit",
    component: CockpitComponent
  },
  {
    templateId: "cgCostcheck",
    component: CostCheckComponent
  },
  {
    templateId: "cgContact",
    component: ContactFormComponent
  },
  {
    templateId: "cgxContactWidget",
    component: CgxContactWidgetComponent
  },
  {
    templateId: "cgxMsg",
    component: CgxMsgComponent
  },
  {
    templateId: "cgxParagraph",
    component: CgxPargraphComponent
  },
  {
    templateId: "cgxPicture",
    component: CgxPictureComponent
  },
  {
    templateId: "cgxStageHome",
    component: CgxStageHomeComponent
  },
  {
    templateId: "cgxStageSub",
    component: CgxStageSubComponent
  },
  {
    templateId: "cgxTeaser",
    component: CgxTeaserComponent
  },
  {
    templateId: "cgxTeaserTwoColumn",
    component: CgxTeaserComponent
  },
  {
    templateId: "cgxUsps",
    component: CgxUspsComponent
  },
  {
    templateId: "cgxWidgets",
    component: CgxWidgetsComponent
  },
  {
    templateId: "cgxHeadline",
    component: CgxHeadlineComponent
  },
  {
    templateId: "cgxTrustNumbers",
    component: CgxTrustNumbersComponent
  },
  {
    templateId: "cgxDamageTypeTable",
    component: CgxDamageTypeTableComponent
  },
  {
    templateId: "cgxCostcheck",
    component: CgxCostCheckComponent
  },
  {
    templateId: "cgxCta",
    component: CgxCtaComponent
  },
  {
    templateId: "cgxContentBlockText",
    component: CgxContentBlockTextComponent
  },
  {
    templateId: "cgxContentBlockImage",
    component: CgxContentBlockImageComponent
  },
  {
    templateId: "cgxContentBlockList",
    component: CgxContentBlockListComponent
  },
  {
    templateId: "cgxContentBlockVideo",
    component: CgxContentBlockVideoComponent
  },
  {
    templateId: "cgxLocationTeaser",
    component: CgxLocationTeaserComponent
  },
  {
    templateId: "cgxTrustpilot",
    component: CgxTrustpilotComponent
  }
];
