import * as Sentry from "@sentry/angular-ivy";
import type { ActionReducer } from "@ngrx/store";
import type { SeverityLevel } from "@sentry/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sentryBreadcrumbMetaReducer(reducer: any): ActionReducer<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (state: any, action: any) => {
    Sentry.addBreadcrumb({
      category: "stateChange",
      message: `Action type: ${action.type}`,
      level: "info" as SeverityLevel
    });
    return reducer(state, action);
  };
}
