import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from "@angular/core";
import { HreflangLanguage, MetaDataService } from "@cg/html-header";
import { JsonLdService } from "@cg/json-ld";
import { MagnoliaResponse } from "../../interfaces/magnolia-response.interface";
import { CmsCgxHostContainerComponent } from "../cms-cgx-host-container/cms-cgx-host-container.component";
import { CmsHostContainerComponent } from "../cms-host-container/cms-host-container.component";

@Component({
  selector: "cg-cms-render-container",
  templateUrl: "./cms-render-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CmsHostContainerComponent, CmsCgxHostContainerComponent]
})
export class CmsRenderContainerComponent {
  private readonly metaDataService = inject(MetaDataService);
  private readonly jsonLdService = inject(JsonLdService);

  public content = input<MagnoliaResponse | null>(null);

  public readonly isPage = computed(() => {
    const validPages = ["contentpage", "homepage", "cgx-page"];

    if (!this.content()) {
      return false;
    }

    const contentType = this.content().ngTemplate;
    return validPages.includes(contentType);
  });

  public readonly isCgxPage = computed(() => this.content()?.ngTemplate === "cgx-page");

  public constructor() {
    effect(() => {
      if (this.content()) {
        this.updateMetaData();
        this.updateHreflangTags();
        this.updateJsonLdData();
      }
    });
  }

  public updateMetaData(): void {
    if (!this.isPage() || !this.content) {
      return;
    }

    const { title, description, keywords, robots } = this.content();

    this.metaDataService.setDocumentTitle(title);
    this.metaDataService.addOrUpdateTag("description", description);
    this.metaDataService.addOrUpdateTag("keywords", keywords);
    this.metaDataService.addOrUpdateTag("robots", robots);
  }

  public updateHreflangTags(): void {
    if (!this.isPage() || !this.content()) {
      return;
    }

    const { hreflangat, hreflangch } = this.content();

    this.metaDataService.setHreflangLink(hreflangat, HreflangLanguage.GERMAN_AUSTRIA);
    this.metaDataService.setHreflangLink(hreflangch, HreflangLanguage.GERMAN_SWITZERLAND);
  }

  public updateJsonLdData(): void {
    this.jsonLdService.removeJsonLdScriptTag();
    this.setPageJsonLdData();
    this.setBreadcrumbJsonLdData();
  }

  public setPageJsonLdData(): void {
    const { jsonLd } = this.content();

    if (jsonLd) {
      this.jsonLdService.updateJsonLdScriptTag(jsonLd);
    }
  }

  public setBreadcrumbJsonLdData(): void {
    const breadcrumb = this.content()?.breadcrumb;

    if (breadcrumb?.jsonLd) {
      this.jsonLdService.updateJsonLdScriptTag(breadcrumb.jsonLd);
    }
  }
}
